function loadKRpanoView(xmlUrl, swfUrl, target = 'pano') {
  embedpano({
    swf: swfUrl,
    xml: xmlUrl,
    target: target,
    html5: 'auto',
    mobilescale: 1.0,
    passQueryParameters: true,
    id: 'krpanoSWFObject',
    onready: krpanoReady,
  });
  console.log(embedpano, 'embedpano');
  var value = document.getElementById('krpanoSWFObject');
  if (value) {
    return true;
  } else {
    return false;
  }
}

function loadKRpanoMarker(xmlUrl, swfUrl) {
  embedpano({
    swf: swfUrl,
    xml: xmlUrl,
    target: 'content-pano',
    html5: 'auto',
    mobilescale: 1.0,
    passQueryParameters: true,
    id: 'krpanoSWFObject',
    onready: krpanoReady,
  });
  console.log(embedpano, 'embedpano');
  var value = document.getElementById('krpanoSWFObject');
  if (value) {
    return true;
  } else {
    return false;
  }
}

function previewLoadKRpanoMarker(xmlUrl, swfUrl) {
  embedpano({
    swf: swfUrl,
    xml: xmlUrl,
    target: 'preview-content-pano',
    html5: 'auto',
    mobilescale: 1.0,
    passQueryParameters: true,
    id: 'krpanoSWFObject3',
    onready: krpanoReady,
  });
  console.log(embedpano, 'embedpano');
  var value = document.getElementById('krpanoSWFObject3');
  if (value) {
    return true;
  } else {
    return false;
  }
}

function krpanoReady(krpano) {
  krpano.call('trace(krpano is ready...)');
}

function loadKRpanoStopView(xmlUrl, swfUrl) {
  embedpano({
    swf: swfUrl,
    xml: xmlUrl,
    target: 'stop-pano',
    html5: 'auto',
    mobilescale: 1.0,
    passQueryParameters: true,
    id: 'stopObject',
  });
}

var krpano;
var currentHotspot = [];

function callKrpano() {
  var krpano = document.getElementById('krpanoSWFObject');
  setTimeout(() => {
    var allHotspots = krpano.get('hotspot.count');

    console.log('allHotspots', allHotspots);

    for (i = 0; i < allHotspots; i++) {
      var hotspots = krpano.get('hotspot[ ' + i + ']');
      var sprite = krpano.get('hotspot[ ' + i + '].sprite');
      console.log(sprite);
      currentHotspot.push(hotspots);

      console.log(currentHotspot);

      if (sprite) {
        var element = document.createElement('div');
        var style = document.createElement('style');
        style.innerHTML = `
        .venue-name {
          font-size: 14px;
          color: #000;
          top: 40px;
          position: absolute;
          left: 0;
          font-weight: 800;
          padding: 3px 20px;
          text-align: center;
          width: 100%;
          line-height: 18px;
          top: 35%;
          transform: translateY(-50%);
          font-family: Futura PT;
          text-transform: capitalize;
        }
        `;
        document.head.appendChild(style);
        element.classList.add('venue-name');
        element.textContent = krpano.get('hotspot[ ' + i + '].name');
        sprite.appendChild(element);

        console.log(' timeput');
        console.log(krpano.get('hotspot[' + i + ']').sprite);

        // hotspots.onhover = function(hotspots) {
        //   console.log('krpanoData', krpano);
        //   console.log(sprite);

        //   if (sprite) {
        //     console.log('in hover Data', hotspots);
        //     var style1 = document.createElement('style');
        //     style1.innerHTML = `
        //             .venue-marker-hover {
        //               background-color: red !important;
        //             }
        //             `;
        //     document.head.appendChild(style1);

        //     sprite.classList.add('venue-marker-hover');
        //   }
        // };

        // hotspots.onout = function() {
        //   // krpano
        //   //   .get('hotspot[' + i + ']')
        //   //   .sprite.classList.remove('venue-marker-hover');
        // };
      }
    }
  }, 2000);
}

function removeExisitingPano() {
  removepano('krpanoSWFObject');
}

function removeExisitingPanoContentMarker() {
  removepano('krpanoSWFObject2');
  // var e = document.querySelector("content-pano");
  //       var first = e.firstElementChild;
  //       while (first) {
  //           first.remove();
  //           first = e.firstElementChild;
  //       }
}

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function addDefaultMarker(krpano, hotspotImage) {
  var mouse_at_h = 13.2154;
  var mouse_at_v = 12.6504;

  krpano = krpano.get('global');

  var defaultHotspot = krpano.addhotspot('auto');
  defaultHotspot.url = hotspotImage;
  defaultHotspot.ath = mouse_at_h;
  defaultHotspot.atv = mouse_at_v;
  defaultHotspot.name = 'default-marker';
  defaultHotspot.accuracy = 1;
  defaultHotspot.alpha = '1.0';
  defaultHotspot.scale = 1;
  defaultHotspot.zoom = false;
  defaultHotspot.rotate = 0;
  defaultHotspot.dragging = true; // a custom setting for enabling/disabling the dragging
  defaultHotspot.enabled = true;
  defaultHotspot.visible = true;
  defaultHotspot.renderer = 'css3d';
  defaultHotspot.edge = 'bottom';
  defaultHotspot.x = 0;
  defaultHotspot.y = -10;
  console.log('defaultHotspot', defaultHotspot);

  defaultHotspot.ondown = function() {
    if (defaultHotspot.dragging) {
      // get the current screen position of the hotspot
      var defaultHotspot_screen = krpano.spheretoscreen(
        defaultHotspot.ath,
        defaultHotspot.atv,
      );

      // get the offset from the hotspot center to the mouse cursor
      var offsetx = krpano.mouse.stagex - defaultHotspot_screen.x;
      var offsety = krpano.mouse.stagey - defaultHotspot_screen.y;

      krpano.actions.asyncloop(
        function() {
          return defaultHotspot.pressed;
        }, // loop as long the mouse is pressed
        function() {
          // subtract the mouse-hotspot offset and get the spherical coordinate
          var pt = krpano.screentosphere(
            krpano.mouse.stagex - offsetx,
            krpano.mouse.stagey - offsety,
          );
          defaultHotspot.ath = pt.x;
          defaultHotspot.atv = pt.y;
        },
      );
    }
  };

  defaultHotspot.onup = function() {};

  defaultHotspot.onclick = function() {
    toggleModal();
  };
}

//add poi hotspot
function addPoiHotSpot(krpano, poiMarkerImage) {
  var mouse_at_h = 13.2154;
  var mouse_at_v = 12.6504;

  krpano = krpano.get('global');

  var poiHotspot = krpano.addhotspot('auto');
  poiHotspot.url = poiMarkerImage;
  poiHotspot.ath = mouse_at_h;
  poiHotspot.atv = mouse_at_v;
  poiHotspot.name = 'default-marker';
  poiHotspot.accuracy = 1;
  poiHotspot.alpha = '1.0';
  poiHotspot.scale = 1;
  poiHotspot.zoom = false;
  poiHotspot.rotate = 0;
  poiHotspot.dragging = true; // a custom setting for enabling/disabling the dragging
  poiHotspot.enabled = true;
  poiHotspot.visible = true;
  poiHotspot.renderer = 'css3d';
  poiHotspot.edge = 'bottom';
  poiHotspot.x = 0;
  poiHotspot.y = -10;
  console.log('poiHotspot', poiHotspot);

  poiHotspot.ondown = function() {
    if (poiHotspot.dragging) {
      // get the current screen position of the hotspot
      var poiHotspot_screen = krpano.spheretoscreen(
        poiHotspot.ath,
        poiHotspot.atv,
      );

      // get the offset from the hotspot center to the mouse cursor
      var offsetx = krpano.mouse.stagex - poiHotspot_screen.x;
      var offsety = krpano.mouse.stagey - poiHotspot_screen.y;

      krpano.actions.asyncloop(
        function() {
          return poiHotspot.pressed;
        }, // loop as long the mouse is pressed
        function() {
          // subtract the mouse-hotspot offset and get the spherical coordinate
          var pt = krpano.screentosphere(
            krpano.mouse.stagex - offsetx,
            krpano.mouse.stagey - offsety,
          );
          poiHotspot.ath = pt.x;
          poiHotspot.atv = pt.y;
        },
      );
    }
  };

  poiHotspot.onup = function() {};

  poiHotspot.onclick = function() {};
}

//add venue hotspot
function addVenueHotspot(krpano, venueMarkerImage) {
  var mouse_at_h = 13.2154;
  var mouse_at_v = 12.6504;

  krpano = krpano.get('global');

  var venueHotspot = krpano.addhotspot('auto');
  venueHotspot.url = poiMarkerImage;
  venueHotspot.ath = mouse_at_h;
  venueHotspot.atv = mouse_at_v;
  venueHotspot.name = 'default-marker';
  venueHotspot.accuracy = 1;
  venueHotspot.alpha = '1.0';
  venueHotspot.scale = 1;
  venueHotspot.zoom = false;
  venueHotspot.rotate = 0;
  venueHotspot.dragging = true; // a custom setting for enabling/disabling the dragging
  venueHotspot.enabled = true;
  venueHotspot.visible = true;
  venueHotspot.renderer = 'css3d';
  venueHotspot.edge = 'bottom';
  venueHotspot.x = 0;
  venueHotspot.y = -10;
  console.log('venueHotspot', venueHotspot);

  venueHotspot.ondown = function() {
    if (venueHotspot.dragging) {
      // get the current screen position of the hotspot
      var venueHotspot_screen = krpano.spheretoscreen(
        venueHotspot.ath,
        venueHotspot.atv,
      );

      // get the offset from the hotspot center to the mouse cursor
      var offsetx = krpano.mouse.stagex - venueHotspot_screen.x;
      var offsety = krpano.mouse.stagey - venueHotspot_screen.y;

      krpano.actions.asyncloop(
        function() {
          return venueHotspot.pressed;
        }, // loop as long the mouse is pressed
        function() {
          // subtract the mouse-hotspot offset and get the spherical coordinate
          var pt = krpano.screentosphere(
            krpano.mouse.stagex - offsetx,
            krpano.mouse.stagey - offsety,
          );
          venueHotspot.ath = pt.x;
          venueHotspot.atv = pt.y;
        },
      );
    }
  };

  venueHotspot.onup = function() {};

  venueHotspot.onclick = function() {};
}

//add stop hotspot
function addStopHotspot(krpano, stopMarkerImage) {
  var mouse_at_h = 13.2154;
  var mouse_at_v = 12.6504;

  krpano = krpano.get('global');

  var stopHotspot = krpano.addhotspot('auto');
  stopHotspot.url = poiMarkerImage;
  stopHotspot.ath = mouse_at_h;
  stopHotspot.atv = mouse_at_v;
  stopHotspot.name = 'default-marker';
  stopHotspot.accuracy = 1;
  stopHotspot.alpha = '1.0';
  stopHotspot.scale = 1;
  stopHotspot.zoom = false;
  stopHotspot.rotate = 0;
  stopHotspot.dragging = true; // a custom setting for enabling/disabling the dragging
  stopHotspot.enabled = true;
  stopHotspot.visible = true;
  stopHotspot.renderer = 'css3d';
  stopHotspot.edge = 'bottom';
  stopHotspot.x = 0;
  stopHotspot.y = -10;
  console.log('stopHotspot', stopHotspot);

  stopHotspot.ondown = function() {
    if (stopHotspot.dragging) {
      // get the current screen position of the hotspot
      var stopHotspot_screen = krpano.spheretoscreen(
        stopHotspot.ath,
        stopHotspot.atv,
      );

      // get the offset from the hotspot center to the mouse cursor
      var offsetx = krpano.mouse.stagex - stopHotspot_screen.x;
      var offsety = krpano.mouse.stagey - stopHotspot_screen.y;

      krpano.actions.asyncloop(
        function() {
          return stopHotspot.pressed;
        }, // loop as long the mouse is pressed
        function() {
          // subtract the mouse-hotspot offset and get the spherical coordinate
          var pt = krpano.screentosphere(
            krpano.mouse.stagex - offsetx,
            krpano.mouse.stagey - offsety,
          );
          stopHotspot.ath = pt.x;
          stopHotspot.atv = pt.y;
        },
      );
    }
  };

  stopHotspot.onup = function() {};

  stopHotspot.onclick = function() {};
}

function removeDefaultHotspotMarker(krpano) {
  console.log('in remove function');
  var name = 'default-marker';
  krpano.call('removehotspot(' + name + ');');
}

function addhotspot(krpano, KrpanoHotspot, venueName) {
  var mouse_at_h = 13.2154;
  var mouse_at_v = 12.6504;

  krpano = krpano.get('global');

  var hs = krpano.addhotspot('auto');
  hs.url = KrpanoHotspot;
  hs.ath = mouse_at_h;
  hs.atv = mouse_at_v;
  hs.name = venueName;
  hs.accuracy = 1;
  hs.alpha = '1.0';
  hs.scale = 1;
  hs.zoom = false;
  hs.rotate = 0;
  hs.dragging = true; // a custom setting for enabling/disabling the dragging
  hs.enabled = true;
  hs.visible = true;
  hs.renderer = 'css3d';
  hs.edge = 'bottom';
  hs.x = 0;
  hs.y = -10;
  console.log('hs', hs);

  console.log(krpano.device.mobile);

  if (krpano.device.mobile == true) {
    hs.url = 'http://45.79.111.106:3100/public/markers/mobile-venue.png';
  }

  // if (krpano.device.desktop) {
  //   hs.url = 'http://localhost:3900/public/markers/mobile-venue.png';
  // }

  hs.onloaded = function() {
    var element = document.createElement('div');
    var style = document.createElement('style');
    style.innerHTML = `
    .venue-name {
      font-size: 16px;
      color: #000;
      top: 40px;
      position: absolute;
      left: 0;
      font-weight: 800;
      padding: 3px 20px;
      text-align: center;
      width: 100%;
      line-height: 18px;
      top: 36%;
      transform: translateY(-50%);
      font-family: Futura PT;
      text-transform: capitalize;
    }
    `;
    document.head.appendChild(style);
    element.classList.add('venue-name');
    element.textContent = hs.name;
    hs.sprite.appendChild(element);
  };

  hs.ondown = function() {
    if (hs.dragging) {
      // get the current screen position of the hotspot
      var hs_screen = krpano.spheretoscreen(hs.ath, hs.atv);

      // get the offset from the hotspot center to the mouse cursor
      var offsetx = krpano.mouse.stagex - hs_screen.x;
      var offsety = krpano.mouse.stagey - hs_screen.y;

      krpano.actions.asyncloop(
        function() {
          return hs.pressed;
        }, // loop as long the mouse is pressed
        function() {
          // subtract the mouse-hotspot offset and get the spherical coordinate
          var pt = krpano.screentosphere(
            krpano.mouse.stagex - offsetx,
            krpano.mouse.stagey - offsety,
          );
          hs.ath = pt.x;
          hs.atv = pt.y;
        },
      );
    }
  };

  hs.onclick = function() {
    console.log('dasd');
    toggleModal();
  };

  hs.onhover = function() {
    console.log('in hover');
    if (hs.sprite) {
      // for css3d only (note - no VR support)
      var style = document.createElement('style');
      style.innerHTML = `
      .venue-marker-hover {
        background-color: red;
      }
      `;
      document.head.appendChild(style);

      hs.sprite.classList.add('venue-marker-hover');
      // hs.sprite.style.border = '5px double red';
    }
  };

  hs.onout = function() {
    console.log('on out');
    hs.sprite.style.border = '';
    hs.sprite.classList.remove('venue-marker-hover');
  };
}

function toggleModal() {
  window.angularComponentReference.zone.run(() => {
    window.angularComponentReference.loadAngularFunction();
  });
}

function lookTo(krpano) {
  var dta = krpano.get('hotspot.count');
  for (i = 0; i < dta; i++) {
    var hotSpotName = krpano.get('hotspot[2].name');
    krpano.call('looktohotspot(' + hotSpotName + ')');
  }
}

function getZoom(krpano) {
  var hlooat = krpano.get('view.hlookat');
  console.log('hlooat', hlooat);
  var vlookat = krpano.get('view.vlookat');
  console.log('vlookat', vlookat);
  var fov = krpano.get('view.fov');
  console.log('fov', fov);
  var obj = { hlookat: hlooat, vlookat: vlookat, fov: fov };
  return obj;
}

function getXml(krpano) {
  var xmlInfo = krpano.get('xml');
  console.log('xmlInfo', xmlInfo);
  return xmlInfo;
}

function getData(krpano) {
  krpano = krpano.get('global');
  var dta = krpano.get('hotspot.count');
  console.log(dta);

  var hotspotArr = [];

  for (i = 0; i < dta; i++) {
    var obj = {};
    console.log(krpano.get('hotspot[ ' + i + ']'));
    console.log(krpano.get('hotspot[ ' + i + '].ath'));
    console.log(krpano.get('hotspot[ ' + i + '].atv'));
    var hotSpotName = krpano.get('hotspot[ ' + i + '].name');
    var hotspotAth = krpano.get('hotspot[ ' + i + '].ath');
    var hotspotAtv = krpano.get('hotspot[ ' + i + '].atv');
    var rotatePos = krpano.get('hotspot[ ' + i + '].rotate');
    var scale = krpano.get('hotspot[ ' + i + '].scale');
    var dragging = krpano.get('hotspot[ ' + i + '].dragging');
    var zoom = krpano.get('hotspot[ ' + i + '].zoom');
    var renderer = krpano.get('hotspot[ ' + i + '].renderer');
    var edge = krpano.get('hotspot[ ' + i + '].edge');
    var x = krpano.get('hotspot[ ' + i + '].x');
    var y = krpano.get('hotspot[ ' + i + '].y');

    obj['name'] = hotSpotName ? hotSpotName : '';
    obj['ath'] = hotspotAth ? hotspotAth : '';
    obj['atv'] = hotspotAtv ? hotspotAtv : '';
    obj['rotate'] = rotatePos ? rotatePos : '';
    obj['scale'] = scale ? scale : '';
    obj['dragging'] = dragging ? dragging : '';
    obj['renderer'] = renderer ? renderer : '';
    obj['zoom'] = zoom ? zoom : false;
    obj['edge'] = edge ? edge : false;
    obj['x'] = x ? x : '';
    obj['y'] = y ? y : '';

    hotspotArr.push(obj);
  }

  console.log('hotspotArr', hotspotArr);
  return hotspotArr;
}

function rotateMarker(krpano, value) {
  var dta = krpano.get('hotspot.count');
  for (i = 0; i < dta; i++) {
    var position = krpano.set('hotspot[ ' + i + '].rotate', value);
    var data = krpano.get('hotspot[ ' + i + ']');
    var info = document.getElementsByClassName('venue-name');
    if (value == 0) {
      info[0].style.transform = 'rotate(-0deg)';
      info[0].style.color = '#32a852';
    }
    if (value == 45) {
      info[0].style.transform = 'rotate(-45deg)';
      info[0].style.color = '#3242a8';
    }
    if (value == 90) {
      info[0].style.transform = 'rotate(-90deg)';
      info[0].style.color = '#6932a8';
    }
    if (value == 135) {
      info[0].style.transform = 'rotate(-135deg)';
      info[0].style.color = '#a83273';
    }
    if (value == 180) {
      info[0].style.transform = 'rotate(-180deg)';
      info[0].style.color = '#a83273';
    }
    if (value == 225) {
      info[0].style.transform = 'rotate(-225deg)';
      info[0].style.color = '#a83273';
    }
    if (value == 270) {
      info[0].style.transform = 'rotate(-270deg)';
      info[0].style.color = '#a83273';
    }
    if (value == 315) {
      info[0].style.transform = 'rotate(-315deg)';
      info[0].style.color = '#a83273';
    }
  }
}

function editMarker(krpano) {
  var hsEdit = currentHotspot[2];
  hsEdit.renderer = 'css3d';
  krpano.call('looktohotspot(' + hsEdit.name + ')');
  hsEdit.onhover = function() {
    if (hsEdit.sprite) {
      // for css3d only (note - no VR support)
      var style = document.createElement('style');
      style.innerHTML = `
      .venue-marker-hover {
        background-color: red !important;
      }
      `;
      document.head.appendChild(style);

      hsEdit.sprite.classList.add('venue-marker-hover');
      // hsEdit.sprite.style.border = '5px double red';
    }
  };
  hsEdit.onout = function() {
    console.log('in hover');
    if (hsEdit.sprite) {
      hsEdit.sprite.classList.remove('venue-marker-hover');
      // hs.sprite.style.border = '5px double red';
    }
  };

  hsEdit.ondown = function() {
    console.log('dasd drag');
    // get the current screen position of the hotspot
    var hs_screen = krpano.spheretoscreen(hsEdit.ath, hsEdit.atv);

    // get the offset from the hotspot center to the mouse cursor
    var offsetx = krpano.get('mouse.x') - hs_screen.x;
    var offsety = krpano.get('mouse.y') - hs_screen.y;

    var krpanoAction = krpano.get('actions');
    krpanoAction.asyncloop(
      function() {
        return hsEdit.pressed;
      }, // loop as long the mouse is pressed
      function() {
        // subtract the mouse-hotspot offset and get the spherical coordinate
        var pt = krpano.screentosphere(
          krpano.get('mouse.stagex') - offsetx,
          krpano.get('mouse.stagey') - offsety,
        );
        hsEdit.ath = pt.x;
        hsEdit.atv = pt.y;
      },
    );
  };
}

function lookToHotSpot(krpano, hotspot) {
  // var ath = krpano.get('')
  // krpano.lookto(get(hotspot[get(name)].ath), get(hotspot[get(name)].atv), ...);
}